import { all, takeLatest, put, select } from "redux-saga/effects";
import * as KYC3_ACTIONS from "./kyc3.duck";
import { getStep3, saveStep3 } from "services/kyc.service";
import { GET_STEP1 } from "../step1/kyc1.saga";
import { selectCurrentUser } from "redux/user/user.duck";

const setStateAction = (payload) => {
  return {
    type: KYC3_ACTIONS.SET_STATE,
    payload: payload
  };
};

function* GET_STEP3() {
  try {
    yield GET_STEP1();
    const currentUser = yield select(selectCurrentUser);
    yield put(setStateAction({ loading: true }));
    const values = yield getStep3(currentUser.company.id);
    console.log({ values });
    if (values.length) {
      yield put(setStateAction({ bankDetails: values }));
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

function* SAVE_STEP3({ payload, callback }) {
  const { values, onNext } = payload;
  try {
    yield put(setStateAction({ loading: true }));
    const currentUser = yield select(selectCurrentUser);
    yield saveStep3({
      ...values,
      userId: currentUser.id,
      companyId: currentUser.company.id
    });
    const updatedValues = yield getStep3(currentUser.company.id);
    if (updatedValues.length) {
      yield put(setStateAction({ bankDetails: updatedValues }));
    }
    onNext && onNext();
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(KYC3_ACTIONS.GET_STEP3, GET_STEP3)]);
  yield all([takeLatest(KYC3_ACTIONS.SAVE_STEP3, SAVE_STEP3)]);
}
