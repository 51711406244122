import { backendAPI } from "utils/httpAPI.util";
import { getAccessToken } from "utils";
import { API_PATHS } from "commons/consts";
import isString from "lodash/isString";

export const getCompanyInfo = async () => {
  const accessToken = await getAccessToken();
  if (accessToken) {
    const companyInfo = await backendAPI.get(accessToken)(API_PATHS.COMPANY_INFO);
    return companyInfo;
  }
  return {};
};

export const updateCompanyInfo = async (values) => {
  const accessToken = await getAccessToken();
  await backendAPI.put(accessToken)(API_PATHS.COMPANY_INFO, values);
};

export const uploadCompanyLogo = async (imgFile) => {
  const accessToken = await getAccessToken();
  const formData = new FormData();
  formData.append("file", imgFile);
  await backendAPI.postFile(accessToken)(API_PATHS.COMPANY_LOGO, formData);
};

/*
 * COMPANY ADDRESSES
 */

export const getCompanyAddresses = async () => {
  const accessToken = await getAccessToken();
  const addresses = await backendAPI.get(accessToken)(API_PATHS.COMPANY_ADDRESSES);
  return addresses;
};

export const createCompanyAddresses = async (values) => {
  const accessToken = await getAccessToken();
  await backendAPI.post(accessToken)(API_PATHS.COMPANY_ADDRESSES, values);
};

export const updateCompanyAddresses = async (id, values) => {
  const accessToken = await getAccessToken();
  await backendAPI.put(accessToken)(`${API_PATHS.COMPANY_ADDRESSES}/${id}`, values);
};

export const deleteCompanyAddresses = async (id) => {
  const accessToken = await getAccessToken();
  await backendAPI.delete(accessToken)(`${API_PATHS.COMPANY_ADDRESSES}/${id}`);
};

/*
 * BANK DETAILS
 */

export const getBankDetails = async (companyId) => {
  const accessToken = await getAccessToken();
  const bankListResponse = await backendAPI.get(accessToken)(
    `/companies/${companyId}/bankAccounts`,
    {
      page: 0,
      size: 1000
    }
  );
  const banks = await Promise.all(
    bankListResponse.data.map((bank) =>
      backendAPI.get(accessToken)(`/companies/${companyId}/bankAccounts/${bank.id}`)
    )
  );
  return banks;
};

export const deleteBank = async ({ companyId, bankId, userId }) => {
  const accessToken = await getAccessToken();
  return await backendAPI.delete(accessToken)(`/companies/${companyId}/bankAccounts/${bankId}`, {
    userId
  });
};

export const submitBankDetails = async (values, companyId, userId) => {
  const accessToken = await getAccessToken();
  const promises = values.map((bank) => {
    if (!bank.id)
      return backendAPI.post(accessToken)(`/companies/${companyId}/bankAccounts`, bank, {
        userId
      });

    const { id, ...updatedData } = bank;
    return backendAPI.put(accessToken)(
      `/companies/${companyId}/bankAccounts/${bank.id}`,
      updatedData,
      {
        userId
      }
    );
  });
  return await Promise.all(promises);
};

/*
 * DOCUMENTS
 */

export const getDocuments = async () => {
  const accessToken = await getAccessToken();
  const result = await backendAPI.get(accessToken)(API_PATHS.DOCUMENTS);
  return result;
};

export const uploadDocument = async (file, type) => {
  const accessToken = await getAccessToken();
  const formData = new FormData();
  formData.append("file", file);
  return await backendAPI.postFile(accessToken)(API_PATHS.DOCUMENTS, formData, {
    type
  });
};

export const uploadDocuments = async (fileList) => {
  const accessToken = await getAccessToken();
  const uploadPromises = fileList.map(({ file, type, url }) => {
    if (isString(file)) {
      return null;
    }
    const formData = new FormData();
    formData.append("file", file);
    const promise = backendAPI.postFile(accessToken)(
      `${url ? url : API_PATHS.DOCUMENTS}`,
      formData,
      {
        type
      }
    );
    return promise;
  });
  const result = await Promise.all(uploadPromises);
  return result;
};

export const deleteDocuments = async (id) => {
  const accessToken = await getAccessToken();
  await backendAPI.delete(accessToken)(`${API_PATHS.DOCUMENTS}/${id}`);
};

export const getAuditLogs = async (params) => {
  const { page, size } = params;
  const accessToken = await getAccessToken();
  const result = await backendAPI.get(accessToken)(`${API_PATHS.GET_AUDIT_LOGS}`, {
    page,
    size
  });
  return result;
};

export const sendEmailConfirmBank = async () => {
  const accessToken = await getAccessToken();
  await backendAPI.get(accessToken)(API_PATHS.COMPANY_SEND_VERIFICATION_CODE);
};

export const verifyBankConfirmCode = async (code) => {
  const accessToken = await getAccessToken();
  await backendAPI.post(accessToken)(`${API_PATHS.COMPANY_SEND_VERIFICATION_CODE}?code=${code}`);
};
