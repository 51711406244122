import { all, takeLatest, put } from "redux-saga/effects";
import { saveStep1, getStep1 } from "services/kyc.service";
import * as KYC1_ACTIONS from "./kyc1.duck";

const setStateAction = (payload) => {
  return {
    type: KYC1_ACTIONS.SET_STATE,
    payload: payload
  };
};

export function* GET_STEP1() {
  try {
    yield put(setStateAction({ loading: true }));
    const values = yield getStep1();
    yield put(setStateAction(values));
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

function* SAVE_STEP1({ payload }) {
  const { values, onNext } = payload;
  try {
    yield put(setStateAction({ loading: true }));
    yield saveStep1(values);
    const updatedValues = yield getStep1();
    yield put(setStateAction(updatedValues));
    onNext && onNext();
  } catch (error) {
    throw error;
  } finally {
    yield put(setStateAction({ loading: false }));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(KYC1_ACTIONS.GET_STEP1, GET_STEP1)]);
  yield all([takeLatest(KYC1_ACTIONS.SAVE_STEP1, SAVE_STEP1)]);
}
